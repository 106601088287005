import React, { useContext } from "react";
import translations from "../data/langData";
import { LanguageContext } from "../context/LanguageContext";
import { Helmet } from "react-helmet";
import banner from "../assets/image/banner.webp";
import LazyLoad from "react-lazy-load";

const Contact = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <Helmet>
        <title>{t.contact}</title>
      </Helmet>
      <div>
     <LazyLoad>
     <div className="banner" style={{ background: `url(${banner})` }}>
          <div className="overlay_banner">
            <div className="overlay_text d-flex justify-content-center align-items-center h-100">
              <h5>{t.contact}</h5>
            </div>
          </div>
        </div>
     </LazyLoad>

        <section className="flat-spacing-21">
          <div className="container">
            <div className="row g-3">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="tf-content-right">
                  <div className="w-100">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12155.824538011233!2d49.8915052!3d40.3876648!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d5d530f9761%3A0xca5ecf034265485d!2zQcSfIFNhcmF5IFJlc2lkZW5jZSwgUWFyYWJhxJ8gQXRsYXLEsSBtZXlkYW7EsQ!5e0!3m2!1str!2saz!4v1723700437786!5m2!1str!2saz"
                      width="100%"
                      height={420}
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="tf-content-left">
                  <h5 className="mb_20">{t.visit}</h5>
                  <div className="mb_20">
                    <p className="mb_15">
                      <strong>{t.address}</strong>
                    </p>
                    <p>{t.addresscontact}</p>
                  </div>
                  <div className="mb_20">
                    <p className="mb_15">
                      <strong>{t.phone}</strong>
                    </p>
                    <p>+994508260099</p>
                  </div>
                  <div className="mb_20">
                    <p className="mb_15">
                      <strong>{t.email}</strong>
                    </p>
                    <p>contact@vertuaz.com</p>
                  </div>
                  <div className="mb_36">
                    <p className="mb_15">
                      <strong>{t.openTime}</strong>
                    </p>
                    <p className="mb_15">
                      {t.monday} - {t.sunday}: 10:00 - 22:00
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;