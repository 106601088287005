import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../components/home/Home'
import HeaderTop from '../components/HeaderTop'
import Footer from '../components/Footer'
import Contact from '../pages/Contact'
import Shop from '../pages/Shop'
import ShopDetails from '../pages/ShopDetails'
import Checkout from '../pages/cart/Checkout'
import About from '../pages/About'
import PrivacePolicy from '../pages/PrivacePolicy'
import Order from '../pages/cart/Order'

const AppRouter = () => {
  return (
   <>
   <BrowserRouter>
   <HeaderTop/>
   <Routes>
    <Route path='/' element={<Home/>}></Route>
    <Route path='/contact' element={<Contact/>}></Route>
    <Route path='/collections' element={<Shop/>}></Route>
    <Route path='/checkout' element={<Checkout/>}></Route>
    <Route path='/order' element={<Order/>}></Route>
    <Route path='/about' element={<About/>}></Route>
    <Route path='/:parentId' element={<Shop/>}></Route>
    <Route path='/terms-of-use' element={<PrivacePolicy/>}></Route>
    <Route path='/collections/:shopTitle' element={<ShopDetails/>}></Route>
   </Routes>
   <Footer/>
   </BrowserRouter>
   
   </>
  )
}

export default AppRouter
