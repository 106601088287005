import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";

const ModalCart = ({ toggle, onCLose }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const localData = JSON.parse(localStorage.getItem("cart")) || [];
  const orderData = JSON.parse(localStorage.getItem("order")) || [];
  const totalPrice = localData.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const handleQuantity = (index, increment) => {
    const updateCart = [...localData];
    const newQuantity = updateCart[index].quantity + increment;
    if (newQuantity >= 1) {
      updateCart[index].quantity = newQuantity;
      localStorage.setItem("cart", JSON.stringify(updateCart));
      window.dispatchEvent(new Event("cartUpdated"));
    }
  };

  const handleRemoveItem = (index) => {
    const removeItem = localData.filter((_, idx) => idx !== index);
    localStorage.setItem("cart", JSON.stringify(removeItem));
    window.dispatchEvent(new Event("cartUpdated"));
  };

  const modalClosed = () => {
    onCLose();
  };
  const productLocal = JSON.parse(localStorage.getItem("cart")) || [];

  return (
    <>
      <div
        className={`modal fullRight fade modal-shopping-cart ${
          toggle ? "show" : ""
        }`}
        style={{
          display: `${toggle ? "block" : "none"}`,
          transition: "250ms",
          background: `${toggle ? "rgb(0 0 0 / 54%)" : ""}`,
        }}
        id="shoppingCart"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="header">
              <div className="title fw-5 aboutDesc">{t.basket}</div>
              {Object.keys(orderData).length ? (
                <div
                  className="d-flex align-items-center"
                  onClick={modalClosed}
                >
                  <NavLink to="/order">
                    <h6 style={{ fontSize: "medium" }}> {t.orders}</h6>
                  </NavLink>
                  <i
                    className="icon icon-arrow1-top-left ms-2"
                    style={{ fontSize: "9px" }}
                  ></i>
                </div>
              )
             : ""
            }
              <span
                className="icon-close icon-close-popup"
                data-bs-dismiss="modal"
                style={{ cursor: "pointer" }}
                onClick={modalClosed}
              />
            </div>
            <div className="wrap">
              <div className="tf-mini-cart-wrap">
                <div className="tf-mini-cart-main">
                  <div className="tf-mini-cart-sroll">
                    <div className="tf-mini-cart-items">
                      {localData.length == 0 ? (
                        <div className="tf-mini-cart-item">
                          <h6 className="aboutDesc">{t.empty}</h6>
                        </div>
                      ) : (
                        localData.map((i, index) => (
                          <div className="tf-mini-cart-item">
                            <div className="tf-mini-cart-image">
                              <a href="#">
                                <img
                                  src={i.icon}
                                  alt="product img"
                                  className="aboutDesc"
                                />
                              </a>
                            </div>
                            <div className="tf-mini-cart-info">
                              <a className="title link aboutDesc" href="#">
                                {i.name_show.split(" (")[0]} x {i.quantity}
                              </a>
                              <div className="meta-variant aboutDesc">
                                {i.value}
                              </div>
                              <div className="price fw-6 aboutDesc">
                                {i.price}₼{" "}
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "grey",
                                    fontWeight: "500",
                                  }}
                                  className="aboutDesc"
                                >
                                  {i.taksit}₼
                                </span>
                              </div>
                              <div className="tf-mini-cart-btns">
                                <div className="wg-quantity small">
                                  <span
                                    className="btn-quantity minus-btn aboutDesc"
                                    onClick={() => handleQuantity(index, -1)}
                                  >
                                    -
                                  </span>
                                  <input
                                    type="text"
                                    name="number"
                                    className="aboutDesc"
                                    value={i.quantity}
                                  />
                                  <span
                                    className="btn-quantity plus-btn aboutDesc"
                                    onClick={() => handleQuantity(index, 1)}
                                  >
                                    +
                                  </span>
                                </div>
                                <div
                                  className="tf-mini-cart-remove aboutDesc"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleRemoveItem(index)}
                                >
                                  {t.remove}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
                <div className="tf-mini-cart-bottom">
                  <div className="tf-mini-cart-bottom-wrap">
                    <div className="tf-cart-totals-discounts">
                      <div className="tf-cart-total aboutDesc">
                        {t.orderTotal}
                      </div>
                      <div className="tf-totals-total-value fw-6 aboutDesc">
                        {totalPrice.toFixed(1)}₼
                      </div>
                    </div>
                    <div className="tf-mini-cart-line" />
                    <div className="tf-mini-cart-view-checkout">
                      <NavLink
                        to={productLocal.length == 0 ? "/collections" : "/checkout"}
                        className="tf-btn btn-fill animate-hover-btn radius-3 w-100 justify-content-center"
                        onClick={modalClosed}
                      >
                        <span className="aboutDesc">{t.checkout}</span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCart;
