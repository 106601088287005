import React, { useContext, useEffect, useState } from 'react'
import { LanguageContext } from '../../context/LanguageContext';
import translations from '../../data/langData';

const Order = () => {
    const [orderLocal, setOrderLocal] = useState(null);
    const { language } = useContext(LanguageContext);
    const t = translations[language];
    useEffect(() => {
      const storedOrder = JSON.parse(localStorage.getItem("order")) || {};
      setOrderLocal(storedOrder);

      const hasRefreshed = localStorage.getItem('hasRefreshed');
      if (!hasRefreshed) {
        localStorage.setItem('hasRefreshed', 'true');
        window.location.reload(); 
      }
    }, []);
    const groupedProducts = (orderLocal?.id || []).reduce((acc, id, index) => {
      if (!acc[id]) {
        acc[id] = {
          name: orderLocal.productName[index],
          quantity: 0,
          price: parseFloat(orderLocal.price[index]),
          address: orderLocal.address,
          note: orderLocal.note,
          email: orderLocal.email,
          payment: orderLocal.payment,
          firstName: orderLocal.firstName,
          lastName: orderLocal.lastName,
          mobile: orderLocal.mobile,
          store: orderLocal.store,
        };
      }
      acc[id].quantity += 1; 
      return acc;
    }, {});
  return (
   <>
            <h5 className="text-center my-5">{t.notcheckout}</h5>
            <div className="tf-page-cart-wrap my-5 mx-5">
      <div className="tf-page-cart-item" id="orderTable">
        <form>
          <table className="tf-table-page-cart">
            <thead>
              <h5>{t.ordered}</h5>
              <tr>
                <th>{t.productName}</th>
                <th>{t.quantity}</th>
                <th>{t.price}</th>
                <th>{t.payment}</th>
                <th>{t.radio}</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(groupedProducts).map(([id, { name, quantity, price, address, payment,store }]) => (
                <tr className="tf-cart-item file-delete" key={id}>
                  <td className="tf-cart-item_product">
                    <div className="cart-info">
                      <a href="product-detail.html" className="cart-title link">
                        {name}
                      </a>
                    </div>
                  </td>
                  <td className="tf-cart-item_quantity" cart-data-title={t.quantity}>
                    <div className="cart-quantity">{quantity}</div>
                  </td>
                  <td className="tf-cart-item_price" cart-data-title={t.price}>
                    <div className="cart-price">{price} ₼</div>
                  </td>
                  <td className="tf-cart-item_total" cart-data-title={t.payment}>
                    <div className="cart-total">{payment === "cash" ? t.cash : t.paymentOnlayn}</div>
                  </td>
                  <td className="tf-cart-item_total" cart-data-title={t.radio}>
                    <div className="cart-total">{address !== "" ? address : t.radio1}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </form>
      </div>
      <div className="tf-page-cart-footer mt-5">
        <div className="tf-cart-footer-inner">
          <div className="tf-page-cart-checkout">
            <div className="container" style={{padding:"0"}}>
              <div className="row g-2">
                {Object.values(groupedProducts).map(
                  ({note, firstName, lastName, email, mobile, quantity, price }) => (
                    <React.Fragment key={`${firstName}-${lastName}`}>
                      <div className="d-flex justify-content-between">
                        <h6 style={{fontSize:"18px"}}>{t.name}</h6>
                        <h6 style={{fontSize:"18px"}}>{firstName}</h6>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h6 style={{fontSize:"18px"}}>{t.last}</h6>
                        <h6 style={{fontSize:"18px"}}>{lastName}</h6>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h6 style={{fontSize:"18px"}}>{t.email}</h6>
                        <h6 style={{fontSize:"18px"}}>{email}</h6>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h6 style={{fontSize:"18px"}}>{t.phone}</h6>
                        <h6 style={{fontSize:"18px"}}>{mobile}</h6>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h6 style={{fontSize:"18px"}}>{t.orderTotal}</h6>
                        <h6 style={{fontSize:"18px"}}>{(quantity * price).toFixed(2)} ₼</h6>
                      </div>
                      <div>
                        <hr />
                        <h6 style={{fontSize:"18px"}}>{t.orderNote} :</h6>
                        <h6 style={{fontSize:"18px"}}>{note == "" ? t.notnote : note}</h6>
                      </div>
                    </React.Fragment>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   </>
  )
}

export default Order
