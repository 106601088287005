import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import homeslider1 from "../../assets/image/homeslider.webp"
import { NavLink } from 'react-router-dom';
import translations from '../../data/langData';
import { LanguageContext } from '../../context/LanguageContext';
import LazyLoad from 'react-lazy-load';

const Slider = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [data,setData] = useState([])
  useEffect(() => {
    axios
      .get("https://myshops.az/apk/app_prod.php?q=vertu")
      .then((res) => {
        const productData = res.data;
        setData(productData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
   <>
   
<div className="tf-slideshow slider-home-4 slider-effect-fade position-relative">
  <div className="swiper tf-sw-slideshow" data-preview={1} data-mobile={1} data-space={0} data-loop="true" data-auto-play="false" data-delay={2000} data-speed={1000}>
    <div className="swiper-wrapper">
      <div className="swiper-slide" lazy="true">
        <div className="wrap-slider " id="homeslider">
          <div className="sliderTitle ">
          <h3 className='animate__animated animate__fadeInUp animate__delay-1s aboutDesc'>
          VERTU <span className='aboutDesc'>IRONFLIP</span>
          </h3>
          <h6 className='text-end animate__animated animate__fadeInUp animate__delay-2s aboutDesc'>{t.innovation}</h6>
          <div className='d-flex justify-content-end mt-2'>
          <NavLink to="/collections">
          <button className='animate__animated animate__fadeInUp animate__delay-2s aboutDesc'>{(t.readmore).toUpperCase()}</button>
          </NavLink>
          </div>
          </div>
          <LazyLoad>
          <img className="lazyload img-fluid aboutDesc" style={{height:"100%"}} data-src={homeslider1} src={homeslider1} alt="fashion-slideshow" />
          </LazyLoad>
        </div>
      </div>
    </div>
  </div>
</div>


<div className="tf-marquee marquee-sm bg_blue-3">
  <div className="wrap-marquee">
   {
    data.map((i,index)=>(
      <div className="marquee-item" key={index}>
      <div className="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width={7} height={6} viewBox="0 0 7 6" fill="none"><path d="M3.5 0L6.53109 5.25H0.468911L3.5 0Z" fill="white" /></svg>
      </div>
      <p className="text text-white text-uppercase fw-6 aboutDesc">{i.name_show.split(" (")[0]}</p>
    </div>
    ))
   }
  </div>
</div>


   
   </>
  )
}

export default Slider
